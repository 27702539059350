import Heading from 'shopper/components/Heading';
import Text from 'shopper/components/Text';

import Image from 'components/Image';

import placeholder from 'lib/placeholder';

import APP from 'constants/app';

const WhatsAppQrCode = () => (
  <div className="hidden md:block">
    <Heading
      as="p"
      className="mb-4 text-center text-lg md:text-left lg:mb-3 lg:text-base"
      size="size5"
    >
      {placeholder('titles.whatsappPromobit')}
    </Heading>
    <Text className="mb-2 text-base lg:text-sm lg:leading-6" size="size1">
      {placeholder('descriptions.whatsappPromobitQrCode')}
    </Text>
    <div className="size-32">
      <Image
        alt="QR Code para acessar os grupos do Promobit no WhatsApp"
        className="-ml-1 dark:ml-0"
        height={128}
        src={`${APP.LOCAL_IMAGE_PATH}/features/whatsapp/qr-code.svg`}
        width={128}
      />
    </div>
  </div>
);

export default WhatsAppQrCode;
